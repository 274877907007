<template>
  <b-card v-if="data">
    <b-row class="pb-50">
      <!-- text and button -->
      <b-col
        sm="6"
        order-sm="1"
        order="2"
        class="d-flex justify-content-between flex-column mt-1 mt-sm-0"
      >
        <div>
          <h2 class="font-weight-bolder mb-25">{{ data.cpms.total }}</h2>
          <b-card-text class="font-weight-bold mb-2"> 产品 </b-card-text>

          <h5 class="font-medium-2">
            <span class="text-success mr-50">{{ data.cpms.inPIAPercent }}</span>
            <span>正在进行PIA</span>
          </h5>
        </div>

        <b-button
          variant="primary"
          class="shadow"
        >
          <span>查看更多 </span>
          <feather-icon icon="ChevronsRightIcon" />
        </b-button>
      </b-col>

      <!-- dropdown and chart -->
      <b-col
        sm="6"
        cols="12"
        order-sm="2"
        order="1"
        class="d-flex justify-content-between flex-column text-right"
      >
        <div>
          <span>产品统计</span>
        </div>

        <!-- apex chart -->
        <vue-apex-charts
          type="bar"
          height="200"
          :options="salesBar.chartOptions"
          :series="data.salesBar.series"
        />
      </b-col>
    </b-row>
    <hr />

    <!-- progress bar -->
    <b-row class="avg-sessions pt-50">
      <b-col cols="6" class="mb-2">
        <b-card-text class="mb-50"> 总产品数量: {{ data.cpms.total }} </b-card-text>
        <b-progress value="50" max="100" height="6px" />
      </b-col>
      <b-col cols="6" class="mb-2">
        <b-card-text class="mb-50">待评估数量：{{data.cpms.prePIA}} </b-card-text>
        <b-progress value="60" max="100" height="6px" variant="warning" />
      </b-col>
      <b-col cols="6">
        <b-card-text class="mb-50">正在PIA: {{ data.cpms.inPIAPercent }}%</b-card-text>
        <b-progress
          value="70"
          max="100"
          height="6px"
          variant="danger"
          class="mt-25"
        />
      </b-col>
      <b-col cols="6">
        <b-card-text class="mb-50">
          已评估数：: {{ data.cpms.afterPIA }}
        </b-card-text>
        <b-progress
          value="90"
          max="100"
          variant="success"
          height="6px"
          class="mt-25"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BDropdown,
  BDropdownItem,
  BProgress,
  BCardText,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";

export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
    BButton,
    BCardText,
    BDropdown,
    BDropdownItem,
    BProgress,
  },
  props: {
    // data: {
    //   type: Object,
    //   default: () => {},
    // },
  },
  data() {
    return {
      data: {
        cpms:{
          total:5021,
          prePIA:1234,
          inPIA:500,
          inPIAPercent:80,
          afterPIA:30,
        },
        salesBar: {
          series: [
            {
              name: "PIA process",
              data: [75, 25, 25, 75, 25, 15, 25],
            },
          ],
        },
      },

      salesBar: {
        chartOptions: {
          chart: {
            sparkline: { enabled: true },
            toolbar: { show: false },
          },
          grid: {
            show: false,
            padding: {
              left: 0,
              right: 0,
            },
          },
          states: {
            hover: {
              filter: "none",
            },
          },
          colors: [
            "#ebf0f7",
            "#ebf0f7",
            $themeColors.primary,
            "#ebf0f7",
            "#ebf0f7",
            "#ebf0f7",
          ],
          plotOptions: {
            bar: {
              columnWidth: "45%",
              distributed: true,
              endingShape: "rounded",
            },
          },
          tooltip: {
            x: { show: false },
          },
          xaxis: {
            type: "numeric",
          },
        },
      },
    };
  },
  methods: {
  },
};
</script>
