<template>
  <b-card>
    <!--overview  & tracker--->
    <b-row>
      <!--overview--->
      <b-col cols="6">
        <cpm-overview />
      </b-col>

      <!--tracker--->
      <b-col cols="6">
        <cpm-tracker />
      </b-col>
    </b-row>

    <!-- search & new -->
    <b-row>
      <b-col cols="3">
        <b-form-select
          placeholder="所在行业"
          v-model="query_industry"
          :options="industryOptions"
        />
      </b-col>  
      <b-col cols="4">
        <b-input-group>
          <b-form-input
            type="text"
            v-model="query_keyword"
            placeholder="公司名称"
            @keypress.enter="doSearch"
          />
          <b-input-group-append is-text>
            <feather-icon
              icon="SearchIcon"
              class="cursor-pointer"
              @click="doSearch"
            />
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="2" class="text-right">
        <b-button variant="primary" @click="doSearch"
          ><feather-icon icon="PlusIcon" class="mr-50" /><span
            class="align-middle"
            >刷新数据</span
          ></b-button
        >
      </b-col>
    </b-row>

    <!-- list -->
    <b-row>
      <!--data list--->
      <b-col cols="12">
        <b-table
          ref="listTable"
          :items="listTableItems"
          :fields="listTableFields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          striped
          hover
          responsive
          :per-page="listTablePerPage"
          :current-page="listTableCurrentPage"
          class="mt-1"
          show-empty
          empty-text="没有找到符合要求的数据"
        >
          <template #cell(manager)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.manager.avatar"
                  :text="data.item.manager.name"
                  variant="light-primary"
                />
              </template>
              <span class="font-weight-bold d-block text-nowrap">
                {{ data.item.manager.name }}
              </span>
              <small class="text-muted">{{ data.item.manager.email }}</small>
            </b-media>
          </template>

          <template #cell(actions)="data">
            <div class="text-right text-nowrap">
              <b-button
                variant="flat-success"
                class="btn-icon"
                @click="viewItem(data)"
                ><feather-icon icon="EyeIcon"
              /></b-button>
            </div>
          </template>
        </b-table>
      </b-col>

      <!--pagination--->
      <b-col cols="12">
        <b-pagination
          v-model="listTableCurrentPage"
          :total-rows="listTableTotalRows"
          :per-page="listTablePerPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </b-card>
</template>


<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BFormSelect,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BTable,
  BBadge,
  BButton,
  BPagination,
  BFormGroup,
  BFormInvalidFeedback,
  BAvatar,
  BFormCheckbox,
  BMedia,
} from "bootstrap-vue";
import CpmOverview from "./CPMOverview.vue";
import CpmTracker from "./CPMTracker.vue";

// import { avatarText } from '@core/utils/filter'

export default {
  name: "CPMList",

  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BTable,
    BBadge,
    BButton,
    BPagination,
    BFormGroup,
    BFormInvalidFeedback,
    BAvatar,
    BFormCheckbox,
    BMedia,
    CpmOverview,
    CpmTracker,
  },

  data() {
    return {
      queryData: null,

      // select options
      industryOptions: [
        { value: "", text: "应用行业" },
        "互联网",
        "商业",
        "贸易",
        "制造业",
        "农业",
        "医疗",
        "物流",
      ],
      complianceOptions: [
        { value: "", text: "产品阶段" },
        "立项规划",
        "开发测试",
        "在线运行",
        "已下线",
      ],

      // query
      query_industry: "",
      query_compliance: "",
      query_keyword: "",

      // list table
      sortBy: "id",
      sortDesc: false,
      listTableFields: [
        { key: "id", label: "编号" },
        { key: "entName", label: "公司名称" },
        { key: "totalfields", label: "总字段数" },
        { key: "basicinfo", label: "个人基本资料" },
        { key: "identity", label: "个人身份信息" },
        { key: "healthy", label: "个人健康生理信息" },
        { key: "recognization", label: "个人生物识别信息" },
        { key: "actions", label: "操作" },
      ],
      listTablePerPage: 5,
      listTablePageOptions: [5, 15, 30],
      listTableTotalRows: 1,
      listTableCurrentPage: 1,

      // edit
      currentItem: null,
      baseInfoVisible: true,
      adminUser: null,
      showEdit: false,
    };
  },

  computed: {
    listTableItems() {
      return this.queryData ? this.queryData.cpms : [];
    },
  },

  mounted() {
    this.doSearch();
  },

  methods: {
    doSearch() {
      // debugger
      this.$http
        .get("/api/cpm/list", {
          params: {
            q: this.query_keyword,
            industry: this.query_industry,
            compliance: this.query_compliance,
          },
        })
        .then((res) => {
          // debugger
          this.queryData = res.data;
        });
    },

    onEditHidden() {
      this.showEdit = false;
    },
  },
};
</script>